import { getGa, getUtmData } from '../../restocare/utils/ga';
import { sendDesignRequest } from '../../../../utils/api';

export const sendDesignFeedback = ({
  email,
  phone,
  name,
  comment,
  captcha,
  file,
  filename,
  from,
}: Record<string, any>) => {
  const referrer = document?.referrer || '';
  const googleId = getGa();
  const utm = getUtmData();

  return sendDesignRequest({
    email,
    phone,
    name,
    file,
    filename,
    comment,
    captcha,
    ...utm,
    googleId,
    referrer,
    from,
  });
};
